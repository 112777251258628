.refresh__date {
    font-size: 1.2rem;
    display: flex;
    font-style: italic;
}

.filter, .style_inventory_container, .recommended_action_container, .review_recommendation_container{
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.charts__axes {
    margin: 0 auto 2rem auto;
}

.charts__axes > span {
    display: block;
    font-size: 1.1rem;
}

.dashboard__container{
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.dashboard__barChart__container{
        display:flex;
        width:100%;
        justify-content:space-around;
}

.chart__item{
    flex: 1; 
    margin: 1rem
}


[role="rowgroup"]:empty {
    display: block;
    text-align: center;
    border: 1px solid #ddd;
    margin: 1rem;
    padding: 1rem;
    width: 100%;
}

[role="rowgroup"]:empty:before {
    content: "No Data";
  }

  .text-wrap {
    white-space: normal; 
  }

.column__left-align {
    width: 100%;
    text-align: left;
    padding: 0 2rem;
}

.country-split-filter {
    position: absolute;
    left: 40%;
    z-index: 10;
    margin-top: 5rem;
    width: 60rem;
}

.no-wrap {
    white-space: nowrap;
}