html {
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

/* scrollbar style code */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #989898;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

.wrapper {
  display: flex;
  background-color: whitesmoke;
}

#content {
  width: 100%;
}

.sub-content {
  padding: 0 0 10px 72px;
  margin-top: 72px;
}

* {
  box-sizing: border-box;
}

.page-heading {
  font-weight: bold;
  margin: 0% 1%;
  font-size: 2rem;
  margin-right: 10px;
  flex: 1 !important;
}

.page-subHeading {
  font-weight: bold;
  font-size: 2rem;
  margin-right: 10px;
  flex: 1 !important;
}

.center-heading {
  margin: 0 auto !important;
}

.mini-heading {
  font-size: 1rem !important;
}

.page-blue-heading {
  color: white;
  background-color: #8cb3cc;
}

.label-heading-bold {
  font-weight: bold;
  margin: 1%;
  font-size: 1rem;
  color: #2f2e2e;
}

.center {
  margin: auto !important;
}

.heading-color-blue {
  color: #44677b;
}

body {
  opacity: 0.8;
  font-family: "Poppins", serif !important;
  font-size: 0.9rem;
  color: #000000;
  text-align: justify;
  background: #f9f9f9 !important;
}

img#brandlogo {
  width: 86%;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7em;
  color: #333;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 0.3rem 1rem 0.3rem 2rem;
  background: #ffffff !important;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  top: 0;
  right: 0;
  left: 0;
  z-index: 800;
  position: fixed;
}

#dropDownProfile.dropdown-menu.show {
  display: block;
  width: 36px;
  min-width: 9rem !important;
  margin-top: -3px;
  margin-left: -82px;
}

button#dropdownMenu2 {
  background: white;
  border: 1px solid white;
}

li.UserIconList {
  font-size: 0.875rem;
  color: gray;
}

button#dropdownMenu2:focus {
  outline: 0px white;
}

button#dropdownMenu2.dropdown-toggle::after {
  display: none !important;
}

.userText {
  padding-top: 10px;
}

.card {
  border-radius: 8px !important;
  background-color: white !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  border: none !important;
  height: 100%;
}

.gray-card {
  border-radius: 8px !important;
  background-color: #fafafa !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  border: none !important;
}

#subcontent {
  padding-bottom: 10px;
  width: 99%;
  padding-right: 2.5%;
  margin: 5% 2.5%;
  padding-left: 2.5%;
}

#content {
  width: 99%;
  padding: 0px;
  /* min-height: 100vh; */
  transition: all 0.3s;
}

#btndiv {
  text-align: center;
  padding: 10px;
  width: 100%;
}

span.badge.badge-primary.tagspill {
  padding: 5px 14px 5px 14px !important;
  font-size: 0.75rem;
}

.form-control {
  font-size: 1.5rem !important;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  font-size: 0.75rem;
}

select.custom-select {
  font-size: inherit;
}

.errorClass {
  font-size: 1.5rem;
  color: red;
  padding: 10px;
  margin: auto;
}
/* 
.circleIcon {

  margin: 0 5%;
} */

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%
}

.circle.green {
  background: green;
}

.circle.yellow {
  background: goldenrod;
}

.circle.blue {
  background: darkblue;
}

.circle.grey {
  background: grey;
  opacity: 0.7;
}

.error {
  color: var(--color-semantic-e-v);
  font-size: 2rem;
  margin: 20px auto;
  /* margin-top: 20px; */
  padding: 10px;
  box-shadow: 3px 0 0 0 red inset, 4px 4px lightgrey;
  width: max-content;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/* btnfilters */

.btn:active {
  background-color: #44677b;
}

.btn.active {
  color: white;
  background-color: #44677b;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #44677b !important;
  color: white !important;
}

.btn-outline-primary {
  color: #44677b !important;
}

.btn-outline-primary:hover {
  color: white !important;
}

/*----------------login css------------------*/

#forgtpas {
  text-align: right;
}

.card.formDiv {
  width: 35%;
  margin: 10px auto;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  padding: 3%;
}

form.border.rounded.p-5.formWidth {
  background-color: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.loginDiv.text-center {
  background: white;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

img#imgStylingJoann {
  width: 34%;
  margin-top: 5%;
  margin-bottom: 1%;
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}

#signInBtn {
  margin-top: -12px;
  margin-bottom: 1%;
}

.text-cyan {
  color: #44677b;
  font-size: 0.813rem;
  cursor: pointer;
}

.loginBorder {
  box-shadow: 0 5px 15px 1px rgba(62, 57, 107, 0.22);
  padding: 5% !important;
  margin-top: 15% !important;
  border-radius: 0px !important;
}

.loginStyling {
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  color: #747789;
}

.inputBorder {
  width: 70%;
  border: 1px solid #cacfe7;
  border-radius: 4px !important;
  margin-top: 30px;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}

.inputBorder:hover {
  border-color: #3096d8;
}

.usernamePos {
  margin-top: 5%;
  height: 2.75rem !important;
}

.btn-primary, .btn-primary.focus, .btn-primary:focus {
  background-color: #44677b !important;
}

.btn-primary:hover, .btn-outline-primary:hover {
  background-color: #44677b !important;
  opacity: 0.9;
  color: #fff;
}

.btn-primary-rounded {
  background-color: #44677b !important;
  opacity: 0.9;
  color: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: none;
  display: inline-block;
}

.watermarkdiv {
  color: white;
  background-color: white;
  /*position: absolute;*/
  /* margin-top: 387px; */
  /*bottom: 11%;*/
  margin-top: -15px !important;
  width: 70px;
  height: 25px;
  z-index: 9;
}

p.promoNameTextalert {
  font-size: 1rem !important;
  font-weight: bold;
}

.tableContainerplans {
  height: 250px;
  overflow-x: auto;
  overflow-y: auto;
}

.hr-dotted {
  border-top: 2px dotted rgba(89, 87, 87, 0.26) !important;
  margin: 5px 0px 5px 0px;
}

.canvas-chart-div {
  margin-top: 6px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.chart-medium-height {
  height: 60vh;
}

.chart-large-height {
  height: 80vh;
}

.fa-plus {
  font-size: 1.2rem;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #44677b !important;
}

div._loading_overlay_wrapper {
  height: 100%;
  width: 100%;
}

.btn-primary:disabled {
  border: 1px solid #44677b;
  background-color: #e2edf4 !important;
  color: #123649 !important;
  pointer-events: none !important;
}

.hyper-link {
  cursor: pointer;
  font-weight: bold;
  color: darkblue;
  text-decoration: underline;
}

.negative-top-margin {
  position: absolute;
  right: 15px;
  left: auto;
  top: 93px;
}

.alert-info {
  background-color: white !important;
  border: none !important;
  font-size: 0.9rem !important;
}

.alert {
  padding: 0.25rem 1.25rem !important;
}

.footer {
  position: absolute;
}

.alignCenterMargin10 {
  display: flex;
  align-items: center;
  margin: 10px 0;
  flex-direction: row;
}

.alignRightMargin10 {
  width: 100%;
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.download-chart {
  font-size: 20px;
}

.filter-dashboard-mode {
  position: absolute;
  right: 15px;
  left: auto;
  top: -50px;
}

.filter-constraints-mode, .undo-constraints-mode {
  position: relative;
  top: 30px;
  margin-right: 2rem;
}

.undo-constraints-mode {
  background-color: var(--color-secondary-e-blue) !important;
  color: var(--color-primary) !important;
  border-color: var(--color-neutral-a-s-b) !important;
}

.undo-constraints-mode:hover {
  background-color: var(--color-secondary-e-blue) !important;
  opacity: 0.8;
  border-color: var(--color-neutral-a-s-b) !important;
}

.required label:after {
  content: "*";
  color: red;
  margin-left: 5px;
}

.note span, .note span:before {
  content: "*";
  color: red;
}

.note {
  display: flex;
  justify-content: center;
  margin: 4px 0;
  font-size: 1.1rem;
}

.fill label:after {
  content: "*";
  color: white;
  margin-left: 5px;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dXPTCV table {
  margin: 0 auto !important;
}

.dMmiJt table {
  margin: 0 auto !important;
}

.modal-footer, .modal-header {
  width: 100%;
  justify-content: center;
}

.modal-header {
  position: absolute;
  top: 0;
}

.modal-middle-container {
  margin-top: 65px;
  overflow-y: auto;
  max-height: 80vh;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-body {
  font-size: 1.3rem;
}

.modal-content {
  /* position: fixed; */
  margin-top: 200px;
  /* width: 50%; */
}

.modalContent {
  margin-top: 100px !important;
}

.setAll__filter {
  flex: 0 0 45%;
  max-width: 45%;
  margin: 10px;
}

.setAll__input, .setAll__input__center {
  display: flex;
  flex-direction: column;
  flex: 0 0 45%;
  max-width: 45%;
  margin: 10px;
}

.setAll__input__center {
  align-items: center;
  width: 45%;
}

.setAll__input__center_3 {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  max-width: 100%;
  margin: 10px;
  align-items: center;
  width: 70%;
}

.setAll__input>input {
  margin-left: 0;
  width: 100px
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px !important;
    margin: 1.75rem auto;
    /* height: 375 !important; */
  }
}

.filter__wrapper {
  display: flex;
  flex-flow: wrap;
}

.modalHeader {
  position: relative;
}

.modalFooter {
  position: relative;
}

.css-1r4vtzz {
  width: 100% !important;
  min-width: 100% !important;
}

button.btn {
  padding: .375rem 0;
  font-size: 1rem;
}

.container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.container__header__with__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  margin: 0;
}

.table table {
  margin: auto;
}

.modal_opacity {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10001;
}

.container__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.container__button>p {
  color: var(--color-neutral-a-s-b);
}

.container__body {
  background-color: white;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.1);
}

.container__tabs {
  margin: 25px 10px 0 10px;
}

.radio, .checkbox {
  margin: 0 1rem;
}

.radio label, .checkbox label {
  padding-left: 4px;
  font-size: 1.5rem;
}

.radio__group, .checkbox__group {
  display: flex;
  justify-content: space-around;
}

.clickable {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
}

/* .async-multi-div > div:last-child {
  z-index: 5 !important;
} */

.clickable:hover {
  color: red;
  opacity: 0.7;
  font-size: 101%;
}

.css-26l3qy-menu, .css-p9s09c-menu, .css-4mp3pp-menu{
  z-index: 5 !important;
}

[data-sticky-td="true"] {
  z-index: 1 !important;
}

.container__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 18px 24px 10px;
  width: 100%;
  max-width: 100%;
}

.th.checkboxForFirstColumn {
  position: sticky;
  left: 0;
  z-index: 3;
}

.css-ik6y5r {
  width: fit-content;
}

input::placeholder {
  font-size: 10px;
}

#date {
  border: 2px solid lightgray;
  background: none;
  display: block;
  padding: 15px 45px;
  width: 200px;
  outline: none;
  color: gray;
  font-size: 1.5rem;
  border-radius: 25px;
  text-align: center;
  transition: 250ms width ease, 250ms border-color ease;
}

/* .custom-class{
  width: inherit !important;
  padding: 5px 0;
  text-align: center;
} */

/* div.sc-gsTCUz.yLbsW > table > thead > tr > th{
  display: block !important;
} */
.back-btn {
  top: 0;
}

.css-4avucx-control, .css-1pu49r4-control {
    background-color: #fff !important;
    border-radius: 2px !important;
    border-width: 1px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 1.36 !important;
    align-items: baseline !important;
    background: #FFF !important;
    box-sizing: border-box !important;
    border-color: rgba(213,213,213,1) !important;
    height: 2.5rem !important;
    overflow-y: auto;
  }

.css-14fvmzd-placeholder, .css-y3wcrs-placeholder {
  color: #4e4c4c !important;
  font-size: 1.14rem;
}

.css-rij6sy-ValueContainer, .css-1b6odlt{
  height: 3rem !important;
  overflow-y: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* .css-26l3qy-menu{
  max-height: 15rem !important;
  overflow-y:visible
} */

.min-100 {
  min-width: 100%;
  min-height: 100%;
}

.value-error {
  font-size: 10px;
  position: absolute;
  bottom: -10px;
  padding: 0;
}

.checkbox_color_code {
  min-width: 100%; 
  min-height: 100%; 
  padding: 3px;
  background-color: lightslategrey;
}

.table-row__color-code {
  background-color: lightgrey;
}

.article__list__single__select {
  width: 30.2rem; 
  display: flex;
  flex-direction: row; 
  align-items: center;
}

.article__list__single__select > div {
  margin-right: 2rem;
  flex: 1; 
}

.close-circle {
  color: red;
  cursor: pointer;
}

.close-circle:hover {
  opacity: 0.7;
}

.warning_container {
  background: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}